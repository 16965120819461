import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  description?: string
  events: ItemProps[]
  title?: string
}

export const EventsList = memo(function EventsList({
  description,
  events,
  title,
}: Props) {
  if (events.length < 1) {
    return null
  }

  return (
    <Container>
      {description || title ? (
        <Head>
          {title ? <Title>{title}</Title> : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
        </Head>
      ) : null}

      <Wrapper row wrap>
        {uniqBy(events, 'title').map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding: 8.125rem 0 17.0625rem;

  @media (max-width: 1023px) {
    padding: 5rem 0 6.25rem;
  }
`

const Head = styled.div`
  max-width: 47.5rem;
  margin: auto;
  padding: 0 1.875rem;
  text-align: center;

  @media (max-width: 1023px) {
    max-width: none;
    padding: 0 2rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.75rem;
  margin-bottom: 1.875rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
    padding: 0 2rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;

  p {
    margin-block-end: 1em;
    &:last-of-type {
      margin-block-end: 0;
    }
  }
`

const Wrapper = styled(FlexBox)`
  margin: 3.625rem -1.5rem 0 0;
  padding: 0 11.944vw;

  > a {
    width: calc(50% - 1.5rem);
    margin: 1.5rem 1.5rem 0 0;
    &:nth-of-type(even) {
      transform: translateY(3.75rem);
    }
  }

  @media (max-width: 1199px) {
    padding: 0 2rem;
  }

  @media (max-width: 1023px) {
    margin: 1.75rem 0 0 0;

    > a {
      width: 100%;
      margin: 0.125rem 0 0 0;
      &:nth-of-type(even) {
        transform: none;
      }
    }
  }
`
